import { Nullable } from '@/types';

export interface UserReport {
  $id?: string;
  schedules?: [];
  ADDITIONAL_FILTER: Nullable<string>;
  DATECREATED: Nullable<string>;
  DATEMODIFIED: Nullable<string>;
  FILTERS_ID: Nullable<number>;
  REPORT_NAME: string;
  STATUS_CD: string;
  USERNAME: string;
  USER_REPORTS_ID: number;
  USE_DEFAULT_FILTER: number;
  user_report_columns: ReportColumn[];
}

export interface CreateRecord {
  CREATED_ON: string;
  CREATED_BY: string;
  UPDATED_ON: string;
  UPDATED_BY: string;
}

export interface ExclusiveReport extends CreateRecord {
  $id: string;
  exclusive_report_catalog: ExclusiveReportCatalog | Reference;
  EXCLUSIVE_REPORTS_ID: number;
  USERNAME: string;
  REPORT_WHERE: string;
  EXCLUSIVE_REPORTS_SETUP_ID: number;
  SORT_OVERRIDE: Nullable<string>;
  SORT_OVERRIDE_DESCRIPTION: Nullable<string>;
  TITLE_OVERRIDE: Nullable<string>;
  ReportTitle: string;
}

export interface ExclusiveReportCatalog extends CreateRecord {
  $id: string;
  TITLE: string;
  REPORT_SQL: string;
  SORT_STRING: string;
  DESCRIPTION: string;
  REPORT_GROUPING: Nullable<string>;
  SORT_DESCRIPTION: string;
  rdl_id: string;
  EXCLUSIVE_REPORTS_ID: number;
  exclusive_report_user_setup: (ExclusiveReport | Reference)[];
}

export enum ReportTypes {
  USER = 'User',
  CANNED = 'canned',
  EXCLUSIVE = 'EXCLUSIVE',
}

export const enum ReportFormat {
  PDF = 'PDF',
  EXCEL = 'EXCEL',
  CSV = 'CSV',
  HTML = 'HTML',
}

export enum ReportOptions {
  FILTER_NUMBER = 1,
  CUSTOM_OD = 2,
}

export type Reference = {
  $ref: string;
};

export interface BaseUserReport {
  FILTERS_ID: Nullable<number>; // null when not filter or number when have a filter selected
  REPORT_NAME: string;
  user_report_columns: ReportColumn[];
  USER_REPORTS_ID?: number;
}

export interface ReportCreateRequest extends BaseUserReport {
  // unknown, always 'A'
  STATUS_CD: string;
  USERNAME: string;
}

export interface ReportUpdateRequest extends BaseUserReport {
  USERNAME: string;
  STATUS_CD: string;
  USER_REPORTS_ID: number;
  DATECREATED: Nullable<string>;
  DATEMODIFIED: string;
  USE_DEFAULT_FILTER: number;
  ADDITIONAL_FILTER: Nullable<string>;
}

export interface BaseReportColumn {
  SORT_DIRECTION: Nullable<number>; // 1 to asc and -1 to desc or null to not sort
  CUSTOM_NAME: string; // name of column
  FIELD_NAMES_ID: number; // id of tag assigned
  SORT_SEQUENCE: Nullable<number>; // null when not have sort or number auto incremental to assign sort order
}

export interface ReportColumn extends BaseReportColumn {
  SEQUENCE: number; // position of column always auto incremental
  USER_REPORTS_ID: number; // repeat of report.USER_REPORTS_ID
  // TODO check if necessary send next fields
  RowStatus?: string; // method 'PUT' or 'POST'
  field_names?: string; // unknown
  user_reports?: Reference; // unknown, always {$ref: '1'}
}

export interface FieldName {
  $id: string;
  user_report_columns: []; // always empty array
  FIELD_NAMES_ID: number;
  FRIENDLY_NAME: string;
  ORACLE_COLUMN: Nullable<string>;
  DESCRIPTION: Nullable<string>;
  SQL_EXPRESSION: Nullable<string>;
  SQL_EXPRESSION_HTML: Nullable<string>;
  USER_TYPE_RESTRICTION: string;
  USER_REPORT_USE: number;
}
